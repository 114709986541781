import React from 'react';
import Landing from '../pages/landing/landing';
import Login from '../pages/auth/login/login';
import Register from '../pages/auth/register/register';
import UserProjects from '../pages/user-projects/user-projects';
import CreateProject from '../pages/create-project/create-project';
import ProjectSettings from '../pages/project-settings/project-settings';
import ProfileSettings from '../pages/profile-settings/profile-settings';
import Demo from '../pages/demo/demo';
import VerifyEmail from '../pages/auth/verify-email/verify-email';
import ConfirmEmail from '../pages/auth/confirm-email/confirm-email';
import PageNotExist from '../components/page-not-exist/page-not-exist';
import ForgotPassword from '../pages/auth/forgot-password/forgot-password';
import ResetPassword from '../pages/auth/reset-password/reset-password';
import UnsubscribeEmails from '../pages/unsubscribe-emails/unsubscribe-emails';
import DashboardHome from '../pages/dashboard-home/dashboard-home';
import PrivacyPolicy from '../pages/legal/privacy-policy';
import CookiePolicy from '../pages/legal/cookie-policy';
import TermsAndConditions from '../pages/legal/terms-and-conditions';
import WidgetProDemo from '../pages/widget-pro-demo/widget-pro-demo';
import WidgetProShowcase from '../pages/widget-pro-showcase/widget-pro-showcase';
import SubscriptionLock from '../components/subscription-inactive-warning/subscription-lock';
import UserBlocked from '../components/subscription-inactive-warning/user-blocked';
import Purchase from '../components/subscription-inactive-warning/purchase';
import AcceptInvitation from '../pages/organization/accept-invitation';
import LoginByToken from '../pages/auth/login-by-token/login-by-token';
import ShareConversation from '../pages/share-conversation/share-conversation';
import NewUserInvitation from '../pages/organization/new-user-invitation';
import { isClientDomain } from '../shared/helpers';
import WidgetProShowcaseHandler from '../pages/widget-pro-showcase-handler/widget-pro-showcase-handler';

export interface IRoute {
  path: string;
  element: React.FC;
}

export enum RouteNames {
  LANDING = '/',
  PRIVACY_POLICY = '/privacy-policy',
  COOKIE_POLICY = '/cookie-policy',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',

  LOGIN = '/login',
  REGISTER = '/register',
  USER_PROJECTS = '/my-projects',
  USER_PROJECTS_WITH_ORG_ID = `/my-projects/:orgId`,
  CREATE_PROJECT = '/my-projects/:orgId/create-project',
  CREATE_PROJECT_TAB_NAME = '/create-project',
  PROJECT_SETTINGS_NO_SELECTED_TAB = '/my-projects/:orgId/project-settings/:id',
  PROJECT_SETTINGS = '/my-projects/:orgId/project-settings/:id/:tab',
  PROJECT_SETTINGS_TAB_NAME = '/project-settings',
  PROJECT_SETTINGS_SUBTAB = '/my-projects/:orgId/project-settings/:id/:tab/:subtab',
  PROFILE_SETTINGS = '/profile-settings/:tab',
  PROFILE_SETTINGS_ORGANIZATION = '/profile-settings/:tab/:orgId',
  PROFILE_SETTINGS_ORGANIZATION_SUBTAB = '/profile-settings/:tab/:orgId/:subtab',
  PROFILE_SETTINGS_NO_PARAMS = '/profile-settings',
  // DEMO_NO_PARAMS = '/demo',
  DEMO_TAB_NAME = '/demo',
  // DEMO = '/demo/:id',
  DEMO = '/my-projects/:orgId/demo/:id',
  WIDGET_PRO_DEMO = '/widget-pro/demo/:id',
  WIDGET_PRO_SHARE = '/widget-pro/share/:id',
  WIDGET_PRO_SHOWCASE = '/widget-pro/showcase/:id',
  WIDGET_PRO_DEMO_NO_PARAMS = '/widget-pro/demo',
  WIDGET_PRO_SHARE_NO_PARAMS = '/widget-pro/share',
  WIDGET_PRO_SHOWCASE_NO_PARAMS = '/widget-pro/showcase',
  VERIFY_EMAIL = '/verify-email',
  CONFIRM_EMAIL = '/confirm-email',
  ACCEPT_INVITATION = '/accept-invitation',
  NEW_USER_INVITATION = '/new-user-invitation',
  PAGE_NOT_EXIST = '/page-not-exist',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  SIGN_IN_GOOGLE = '/sign_in_google',
  UNSUBSCRIBE_EMAILS = '/unsubscribe-emails',
  SUBSCRIPTION_LOCK = '/subscription_lock',
  USER_BLOCKED = '/user_blocked',
  PURCHASE = '/purchase',
  LOGIN_BY_TOKEN = '/login-by-token', //generates onthe back side!
  SHARE_CONVERSATION = '/conversation/:token',
}

export const landingRoutesLegal: IRoute[] = [
  {
    path: RouteNames.LANDING,
    element: Landing,
  },
  {
    path: RouteNames.PRIVACY_POLICY,
    element: PrivacyPolicy,
  },
  {
    path: RouteNames.COOKIE_POLICY,
    element: CookiePolicy,
  },
  {
    path: RouteNames.TERMS_AND_CONDITIONS,
    element: TermsAndConditions,
  },
];

export const landingRoutes: IRoute[] = [
  {
    path: RouteNames.LANDING,
    element: Landing,
  },
];

const defaultDashboardRoutes: IRoute[] = [
  {
    path: '/',
    element: DashboardHome,
  },
  {
    path: RouteNames.LOGIN,
    element: Login,
  },
  {
    path: RouteNames.USER_PROJECTS,
    element: UserProjects,
  },
  {
    path: RouteNames.USER_PROJECTS_WITH_ORG_ID,
    element: UserProjects,
  },
  {
    path: RouteNames.CREATE_PROJECT,
    element: CreateProject,
  },
  {
    path: RouteNames.PROJECT_SETTINGS_NO_SELECTED_TAB,
    element: ProjectSettings,
  },
  {
    path: RouteNames.PROJECT_SETTINGS,
    element: ProjectSettings,
  },
  {
    path: RouteNames.PROJECT_SETTINGS_SUBTAB,
    element: ProjectSettings,
  },
  {
    path: RouteNames.PROFILE_SETTINGS_ORGANIZATION,
    element: ProfileSettings,
  },
  {
    path: RouteNames.PROFILE_SETTINGS_ORGANIZATION_SUBTAB,
    element: ProfileSettings,
  },
  {
    path: RouteNames.PROFILE_SETTINGS,
    element: ProfileSettings,
  },
  {
    path: RouteNames.DEMO,
    element: Demo,
  },
  {
    path: RouteNames.WIDGET_PRO_DEMO,
    element: WidgetProDemo,
  },
  {
    path: RouteNames.WIDGET_PRO_SHARE,
    element: WidgetProDemo,
  },
  {
    path: RouteNames.WIDGET_PRO_SHOWCASE,
    element: WidgetProShowcaseHandler,
  },
  {
    path: RouteNames.VERIFY_EMAIL,
    element: VerifyEmail,
  },
  {
    path: RouteNames.CONFIRM_EMAIL,
    element: ConfirmEmail,
  },
  {
    path: RouteNames.ACCEPT_INVITATION,
    element: AcceptInvitation,
  },
  {
    path: RouteNames.NEW_USER_INVITATION,
    element: NewUserInvitation,
  },
  {
    path: RouteNames.PAGE_NOT_EXIST,
    element: PageNotExist,
  },
  {
    path: RouteNames.FORGOT_PASSWORD,
    element: ForgotPassword,
  },
  {
    path: RouteNames.RESET_PASSWORD,
    element: ResetPassword,
  },
  {
    path: RouteNames.UNSUBSCRIBE_EMAILS,
    element: UnsubscribeEmails,
  },
  {
    path: RouteNames.SUBSCRIPTION_LOCK,
    element: SubscriptionLock,
  },
  {
    path: RouteNames.USER_BLOCKED,
    element: UserBlocked,
  },
  {
    path: RouteNames.PURCHASE,
    element: Purchase,
  },
  {
    path: RouteNames.LOGIN_BY_TOKEN,
    element: LoginByToken,
  },
  {
    path: RouteNames.SHARE_CONVERSATION,
    element: ShareConversation,
  },
];

const optionalDashboardRoutes: IRoute[] = [
  {
    path: RouteNames.REGISTER,
    element: Register,
  },
];

export const dashboardRoutes =
  process.env.REACT_APP_SHOW_REGISTER === 'false' || isClientDomain()
    ? [...defaultDashboardRoutes]
    : [...defaultDashboardRoutes, ...optionalDashboardRoutes];
