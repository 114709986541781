import React, { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import WidgetProShowcase from '../widget-pro-showcase/widget-pro-showcase';
import WidgetProShowcaseWebsite from '../widget-pro-showcase-website/widget-pro-showcase-website';

const WidgetProShowcaseHandler: FC = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [urlSearchParam, setUrlSearchParam] = useState<string | null>(null);

  useEffect(() => {
    setUrlSearchParam(searchParams.get('url'));
  }, [searchParams]);

  const getComponent = (urlSearchParam: string | null) => {
    if (urlSearchParam) {
      return <WidgetProShowcaseWebsite url={urlSearchParam} />;
    } else {
      return <WidgetProShowcase />;
    }
  };

  return <>{getComponent(urlSearchParam)}</>;
};

export default WidgetProShowcaseHandler;
