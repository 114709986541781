import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { showErrorMessage } from '../../../../shared/helpers';
import { Form, Input, InputNumber, Modal, Button, DatePicker } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import { whatsAppOutboundApi } from '../../../../services/what-app-outbound-service';
import Loading from '../../../loading/loading';
import IWhatsAppOutboundSettings from '../../../../types/IWhatsAppOutboundSettings';
import { V_REQUIRED_FIELD } from '../../../../shared/constants';
import { tablesApi } from '../../../../services/tables-service';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useParams } from 'react-router-dom';
import FormSelect from './wa-set-up-select';
import HighlightPreviewText from './highlight-preview-text';
import ArrayKeyInputValueSelectField from '../../../project-settings/whats-app-outbound/campaigns/array-key-input-value-select-field';
import CreatingTemplateModal from '../../../project-settings/whats-app-outbound/twilio-templates/creating-template-modal';
import TwilioTemplatePreview from '../../../project-settings/whats-app-outbound/twilio-templates/twilio-template-preview';
import { ITwilioTemplateBodyContent } from '../../../../types/whats-app-outbound-service/ICampaignsOptions';
import { projectsAPI } from '../../../../services/projects-service';
import TimeZone from '../../../../shared/time/time-zone';
import { allTimezones, useTimezoneSelect } from 'react-timezone-select';

const labelStyle = 'original';
const timezones = {
  ...allTimezones,
};

dayjs.extend(utc);
dayjs.extend(timezone);

interface IWhatsAppSetUpModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  projectId: string;
  campaignId: string;
  tableId: string;
  messageApi: MessageInstance;
  disableChanging: boolean;
}

interface TemplateVariables {
  id: string;
  displayName: string;
}

const WhatsAppSetUpModal: FC<IWhatsAppSetUpModal> = ({
  isOpen,
  setIsOpen,
  projectId,
  campaignId,
  tableId,
  messageApi,
  disableChanging,
}) => {
  const { orgId } = useParams();
  const [templateId, setTemplateId] = useState<string | undefined>();
  const [templateBody, setTemplateBody] = useState<string>(
    'The template preview will be updated after selection.'
  );
  const [tempateVariables, setTemplateVariables] = useState<
    TemplateVariables[]
  >([]);
  const { data: tableData, isLoading: tableLoading } =
    tablesApi.useGetTableDataTQuery({
      projectId,
      tableId,
    });
  const { data: campaignsOptions, isLoading: campaignsOptionsLoading } =
    whatsAppOutboundApi.useGetCampaignsSetUpOptionsQuery({ projectId });
  const { data: templatePreview, isLoading: templatePreviewLoading } =
    whatsAppOutboundApi.useGetCampaignsTemplatePreviewQuery(
      { projectId, templateId },
      { skip: !templateId }
    );
  const [
    lazyGetCampaignInfo,
    { data: campaignData, isLoading: campaignLoading },
  ] = whatsAppOutboundApi.useLazyGetCampaignInfoQuery();

  const [getProject, { data: projectInfo, isLoading: projectInfoLoading }] =
    projectsAPI.useLazyGetUserProjectByIdQuery();
  const [updateCampaignInfo, { isLoading: updateCampaignInfoLoading }] =
    whatsAppOutboundApi.useUpdateCampaignInfoMutation();
  const [form] = Form.useForm();
  const [okDisabled, setOkDisabled] = useState<boolean>(true);
  const [fromIdValue, setFromIdValue] = useState<string>('');
  const [creatingModalIsOpen, setCreatingModalIsOpen] =
    useState<boolean>(false);
  const [refreshToggle, setRefreshToggle] = useState(false);
  const [selectedTemplate, setSelectedTemplate] =
    useState<ITwilioTemplateBodyContent | null>(null);
  const [selectedTemplateType, setSelectedTemplateType] = useState<
    string | null
  >(null);
  const [isShowTimeZone, setIsShowTimeZone] = useState<boolean>(false);

  const { options: timezoneOptions } = useTimezoneSelect({
    labelStyle,
    timezones,
  });

  const refresh = () => {
    setRefreshToggle((prevToggle) => !prevToggle);
  };

  const getCampaignInfo = async () => {
    await lazyGetCampaignInfo({
      projectId,
      orgId,
      campaignId,
    });
  };

  const getProjectInfo = async () => {
    await getProject({ id: projectId, orgId: orgId });
  };

  useEffect(() => {
    getCampaignInfo();
    getProjectInfo();
  }, [projectId, refreshToggle]);

  const getTimeZone = () => {
    if (campaignData?.timeZone) return campaignData.timeZone;

    if (projectInfo?.regionalSettings?.timeZone?.name)
      return projectInfo?.regionalSettings?.timeZone?.name;

    return 'Etc/GMT';
  };

  useEffect(() => {
    if (campaignData && projectInfo) {
      const startAt = campaignData.startAt
        ? dayjs.utc(campaignData.startAt)
        : null;
      let utcDate = null;

      if (startAt) {
        const offset = timezoneOptions.find(
          (t: any) => t.value === getTimeZone()
        )?.offset;
        utcDate = dayjs.utc(startAt).add(offset || 0, 'h');
        setIsShowTimeZone(true);
      }

      const dailyLimit = campaignData.conversationsDailyLimit;

      const fieldsValues = {
        ...campaignData,
        variablesPair: transformVariablesPairToArray(
          campaignData.variablesPair || {}
        ),
        startAt: utcDate,
        conversationsDailyLimit:
          dailyLimit && dailyLimit > 0 ? dailyLimit : 1000,
        timeZone: getTimeZone(),
      };

      form.setFieldsValue(fieldsValues);
      handleChange();
    }

    if (tableData) {
      const tableColumnsNames = tableData.columns.map((col) => col.name);

      let defPhoneValue = undefined;

      tableColumnsNames.forEach((name) => {
        if (name.toLowerCase() === 'phone') {
          defPhoneValue = name;
        }
      });

      if (defPhoneValue) {
        form.setFieldValue('phoneHeader', defPhoneValue);
        handleChange();
      }
    }
  }, [campaignData, tableData]);

  useEffect(() => {
    if (fromIdValue && campaignsOptions) {
      const channelOptions =
        campaignsOptions.messagingServices.find(
          (service) => service.id === fromIdValue
        )?.channels || [];
      const defaultChannel =
        channelOptions.length === 1 ? channelOptions[0] : undefined;
      form.setFieldValue('channel', defaultChannel);
      handleChange();
    }
  }, [fromIdValue]);

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleUpdate = async (values: IWhatsAppOutboundSettings) => {
    const res = await updateCampaignInfo({
      projectId,
      campaignId,
      body: values,
    });
    if ('error' in res) {
      await showErrorMessage(messageApi, res.error);
    } else {
      messageApi.success('Setting updated.');
      setIsOpen(false);
    }
  };

  const handleSetUp = async () => {
    const values = form.getFieldsValue();
    const variablesPair: { [key: string]: string } = {};
    const formVarsData = values.variablesPair;

    const date = form.getFieldValue('startAt');
    const currentTimeZone = form.getFieldValue('timeZone');

    if (currentTimeZone) {
      const currentTimeZoneOffset = new Date().getTimezoneOffset();
      const timezoneOffsetHours = currentTimeZoneOffset / 60;
      const selectedTimeZoneOffset = timezoneOptions.find(
        (t: any) => t.value === currentTimeZone
      )?.offset;
      const finalOffset = selectedTimeZoneOffset
        ? selectedTimeZoneOffset + timezoneOffsetHours
        : timezoneOffsetHours;

      const zonedDate = dayjs.utc(date).add(finalOffset * -1, 'h');
      values['startAt'] = zonedDate;
    }

    for (let i = 0; i < formVarsData.length; i++) {
      const keyValue = formVarsData[i];
      const key = keyValue.key;
      const value = keyValue.value;
      variablesPair[key] = value;
    }

    const formValuesCopy = {
      ...JSON.parse(JSON.stringify(values)),
      variablesPair,
    };

    handleUpdate(formValuesCopy);
  };

  useEffect(() => {
    if (templatePreviewLoading) {
      setTemplateBody('Loading...');
      return;
    }

    if (!templatePreview) return;

    setTemplateBody(templatePreview.body);

    const mergeArrays = (data: any, target: any) => {
      const targetKeys = target.map((item: any) => item.key);

      data.forEach((item: any) => {
        if (!targetKeys.includes(item.id)) {
          target.push({ key: item.id, value: '' });
        }
      });

      return target;
    };

    const variables = templatePreview.variables;

    if (variables.length > 0) {
      const currents = form.getFieldValue('variablesPair');

      mergeArrays(variables, currents);
    }

    handleChange();
  }, [templatePreview, templatePreviewLoading]);

  const handleChange = async () => {
    const fromId = form.getFieldValue('fromId');
    if (fromId) {
      setFromIdValue(fromId);
    } else {
      setFromIdValue('');
    }

    const templateId = form.getFieldValue('templateId');

    if (templateId) {
      setTemplateId(templateId);
    }

    if (!templatePreview) return;

    const startAt = form.getFieldValue('startAt');

    if (startAt) {
      setIsShowTimeZone(true);
    }

    const variables = templatePreview.variables;
    const currents = await form.getFieldValue('variablesPair');

    let isEmptyVars = false;

    currents.forEach((d: { key: string; value: string }) => {
      if (d.key.length === 0 || !d.value || d.value.length === 0) {
        isEmptyVars = true;
      }
    });

    const handlePreview = () => {
      const listEmpty: TemplateVariables[] = [];

      variables.forEach((v: TemplateVariables) => {
        const data = currents.filter((c: any) => c.key === v.id);

        if (data.length === 0) {
          listEmpty.push(v);
        } else {
          const first = data[0];

          if (first.value.length === 0) {
            listEmpty.push(v);
          }
        }
      });

      setTemplateVariables(listEmpty);
    };

    handlePreview();

    if (isEmptyVars) {
      setOkDisabled(true);
      return;
    }

    try {
      await form.validateFields();
      setOkDisabled(false);
    } catch (errors) {
      setOkDisabled(true);
    }
  };

  const transformVariablesPairToArray = (variablesPair: {
    [key: string]: string;
  }) => {
    return Object.keys(variablesPair).map((key) => ({
      key: key,
      value: (variablesPair as { [key: string]: string })[key],
    }));
  };

  const renderComponent = () => {
    if (
      campaignLoading ||
      tableLoading ||
      campaignsOptionsLoading ||
      projectInfoLoading
    ) {
      return <Loading height={'200px'} />;
    }

    if (!tableData) {
      return <div>Can't get table data.</div>;
    }

    if (!campaignsOptions) {
      return (
        <div>
          Can't access campaign options. Please set up the Twilio plugin in
          Plugins.
        </div>
      );
    }

    const selectOptions = tableData.columns.map((col) => ({
      value: col.name,
      label: col.name,
    }));

    const channelOptions =
      campaignsOptions.messagingServices.find(
        (service) => service.id === fromIdValue
      )?.channels || [];

    return (
      <Form
        className="data-source-upload-modal-form"
        layout="vertical"
        form={form}
        onChange={handleChange}
        requiredMark={false}
      >
        <Form.Item
          name="name"
          label={'Campaign name:'}
          rules={[
            {
              required: true,
              message: V_REQUIRED_FIELD,
            },
          ]}
        >
          <Input />
        </Form.Item>
        {FormSelect(
          'fromId',
          'Messaging Service:',
          campaignsOptions.messagingServices,
          handleChange
        )}
        {FormSelect(
          'channel',
          'Channel:',
          channelOptions,
          handleChange,
          '',
          ''
        )}
        {FormSelect(
          'templateId',
          'Template:',
          campaignsOptions.contentTemplates,
          handleChange
        )}
        <Form.Item label={'Template Preview:'}>
          <HighlightPreviewText
            content={templateBody}
            variables={tempateVariables}
          />
        </Form.Item>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 5,
            marginBottom: 8,
          }}
        >
          <Button
            className="setup-template-button"
            onClick={() => {
              setIsOpen(false);
              setCreatingModalIsOpen(true);
            }}
          >
            Create new template
          </Button>
          <Button
            className="setup-template-button"
            onClick={() => {
              const templateId = form.getFieldValue('templateId');

              if (!templateId) return;

              const templateData = campaignsOptions.contentTemplates.filter(
                (t) => t.id === templateId
              );

              if (templateData.length === 0) return;

              setSelectedTemplateType(templateData[0].type);
              setSelectedTemplate(templateData[0].content);
            }}
          >
            Full template preview
          </Button>
        </div>
        {FormSelect(
          'firstNameHeader',
          'First name:',
          tableData.columns,
          handleChange,
          'name',
          'name'
        )}
        {FormSelect(
          'phoneHeader',
          'Phone header:',
          tableData.columns,
          handleChange,
          'name',
          'name'
        )}
        <ArrayKeyInputValueSelectField
          selectOptions={selectOptions}
          label={'Variables'}
          name={'variablesPair'}
          key={'variablesPair'}
          tooltip={''}
          placeholders={{ key: 'Key', value: 'Value' }}
          V_REQUIRED_FIELD={V_REQUIRED_FIELD}
          isRequired={false}
          validators={{ keyValidators: [], valueValidators: [] }}
          onChange={handleChange}
          templatePreview={templatePreview}
        />
        <Form.Item
          name="conversationsDailyLimit"
          label={'Conversations daily limit:'}
          rules={[
            {
              required: true,
              message: V_REQUIRED_FIELD,
            },
            {
              type: 'number',
              min: 1,
              message: 'Minimum daily limit is 1',
            },
          ]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item label={'Start time:'} name="startAt">
          <DatePicker
            format="YYYY-MM-DD HH:mm:ss"
            popupStyle={{ zIndex: 2000 }}
            showNow={false}
            placeholder={'now'}
            style={{ width: '100%' }}
            onChange={handleChange}
            showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
          />
        </Form.Item>
        {isShowTimeZone && <TimeZone handleChange={handleChange} />}
      </Form>
    );
  };

  const closePreviewModal = () => {
    setSelectedTemplate(null);
    setSelectedTemplateType(null);
  };

  return (
    <>
      <Modal
        title="Set Up"
        open={isOpen}
        onOk={handleSetUp}
        okButtonProps={{
          disabled: okDisabled || updateCampaignInfoLoading || disableChanging,
          loading: updateCampaignInfoLoading,
        }}
        onCancel={handleCancel}
        centered={true}
        className={'text-source-modal'}
        wrapClassName={'modal'}
        maskStyle={{ zIndex: 1100 }}
        style={{ marginTop: 20, marginBottom: 20, maxWidth: 600 }}
      >
        {renderComponent()}
      </Modal>

      <CreatingTemplateModal
        isOpen={creatingModalIsOpen}
        setIsOpen={setCreatingModalIsOpen}
        projectId={projectId}
        onRefresh={refresh}
      />

      <Modal
        open={!!selectedTemplate}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={closePreviewModal}
        onOk={closePreviewModal}
        title="Template preview:"
        className="template-preview"
      >
        <TwilioTemplatePreview
          content={selectedTemplate}
          type={selectedTemplateType}
        />
      </Modal>
    </>
  );
};

export default WhatsAppSetUpModal;
