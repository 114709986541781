import { Form, Select } from 'antd';
import { FC } from 'react';
import { allTimezones, useTimezoneSelect } from 'react-timezone-select';

const labelStyle = 'original';
const timezones = {
  ...allTimezones,
};

interface ITimeZone {
  handleChange: () => Promise<void>;
}

const TimeZone: FC<ITimeZone> = ({ handleChange }) => {
  const { options: timezoneOptions } = useTimezoneSelect({
    labelStyle,
    timezones,
  });

  return (
    <Form.Item label="Timezone: " name={'timeZone'}>
      <Select
        style={{ zIndex: 20000 }}
        dropdownStyle={{ zIndex: 20000 }}
        options={timezoneOptions}
        onChange={handleChange}
        showSearch
      />
    </Form.Item>
  );
};

export default TimeZone;
