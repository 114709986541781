import { Alert, Button, Divider, Result } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import './project-settings.scss';
import Knowledge, {
  IKnowledgeDataType,
} from '../../components/project-settings/knowledge/knowledge';
import Settings from '../../components/project-settings/settings/settings';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import CheckAuth from '../../components/check-auth/check-auth';
import { RouteNames } from '../../router/router';
import { projectsAPI } from '../../services/projects-service';
import {
  KnowledgeStatus,
  LS_USER_USAGE_INFO,
  LS_USER_USAGE_INFO_UPDATED_AT,
  OrganizationMemberRole,
  ProjectSettingsType,
  UserRoles,
} from '../../shared/constants';
import { subscriptionAPI } from '../../services/subscription-service';
import { useAppSelector } from '../../hooks/redux-hooks';
import IUsageInfoLimitsClosedLS from '../../types/IUsageInfoLimitsClosedLS';
import LimitWarning from '../../components/limit-warning/limit-warning';
import SubscriptionInactiveWarning from '../../components/subscription-inactive-warning/subscription-inactive-warning';
import Plugins from '../../components/project-settings/plugins/plugins';
import Insights from '../../components/project-settings/insights/insights';
import Conversations from '../../components/project-settings/conversations/conversations';
import Loading from '../../components/loading/loading';
import { getErrorMessage } from '../../shared/helpers';
import BreadcrumbNav from '../../components/breadcrumb-nav/breadcrumb-nav';
import Records from '../../components/project-settings/records/records';
import { pluginsAPI } from '../../services/plugins-service';
import Usage from '../../components/project-settings/usage/usage';
import ProjectSettingsMenu from './project-settings-menu';
import ProjectSettingsSideMenu from './project-settings-side-menu';
import Analytics from '../../components/project-settings/analytics/analytics';
import { organizationsAPI } from '../../services/organizations-service';
import Prompt from '../../components/project-settings/prompt/prompt';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../i18n';
import { getProjectSettingsOptions } from './project-settings-helpers';
import Tables from '../../components/project-settings/tables/tables';
import TwilioOutbound from '../../components/project-settings/whats-app-outbound/twilio-outbound';
import {
  IProjectSettingsOptions,
  ProjectSettingDefaultOptions,
} from './project-settings-options';

const STATUS_UPDATE_MS = 10000;

const ProjectSettings: FC = () => {
  const { t } = useTranslation();
  const { orgId, id, tab, subtab } = useParams();
  const {} = pluginsAPI.useGetPluginsQuery();
  const [
    lazyGetUserProjectById,
    {
      data: lazyUserProject,
      error: lazyProjectError,
      isLoading: lazyUserProjectLoading,
    },
  ] = projectsAPI.useLazyGetUserProjectByIdQuery();
  const [
    lazyGetProjectPlugins,
    {
      data: lazyProjectPlugins,
      error: lazyProjectPluginsError,
      isLoading: lazyProjectPluginsLoading,
    },
  ] = pluginsAPI.useLazyGetProjectPluginsQuery();
  const { plugins } = useAppSelector((state) => state.pluginsReducer);
  const { isDomainTenantMember } = useAppSelector((state) => state.userReducer);
  const [idForKnowledge, setIdForKnowledge] = useState<string | null>(null);
  const [pollingInterval, setPollingInterval] = useState<number | undefined>(
    STATUS_UPDATE_MS
  );
  const [skip, setSkip] = useState<boolean>(true);
  const {
    data: projectKnowledge,
    error,
    isLoading,
  } = projectsAPI.useGetProjectKnowledgeQuery(
    { projectId: idForKnowledge || '', orgId },
    { skip, pollingInterval: pollingInterval }
  );
  const {} = subscriptionAPI.useGetUsageQuery(undefined, {
    pollingInterval: pollingInterval,
  });
  const [lazyGetUserSubscription] =
    subscriptionAPI.useLazyGetUserSubscriptionQuery();
  const [lazyGetSubscriptionIsActive] =
    subscriptionAPI.useLazyGetSubscriptionIsActiveQuery();
  const [
    lazyGetUnreadConversationsCount,
    { data: lazyGetUnreadConversationsCountData },
  ] = projectsAPI.useLazyGetUnreadConversationsCountQuery();
  const [lazyGetAllOrganizationMembers, { data: allOrganizationMembersData }] =
    organizationsAPI.useLazyGetAllOrganizationMembersQuery();
  const { userUsage, subscriptionIsActive, currentUserSubscription } =
    useAppSelector((state) => state.subscriptionReducer);
  const { knowledgeParentId } = useAppSelector((state) => state.projectReducer);
  const {
    currentOrganizationLoading,
    currentOrganization,
    allOrganizationsInfo,
  } = useAppSelector((state) => state.organizationsReducer);
  const { user, userRole } = useAppSelector((state) => state.userReducer);
  const navigate = useNavigate();
  const [knowledgeList, setKnowledgeList] = useState<IKnowledgeDataType[]>([]);
  const [softWarning, setSoftWarning] = useState(false);
  const [hardWarning, setHardWarning] = useState(false);
  const [options, setOptions] = useState<IProjectSettingsOptions[]>(
    ProjectSettingDefaultOptions
  );
  const [ownerIdOfCurrentOrg, setOwnerIdOfCurrentOrg] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (orgId) {
      lazyGetAllOrganizationMembers({ orgId });
    }
  }, [orgId]);

  useEffect(() => {
    if (idForKnowledge) {
      setSkip(false);
    }
  }, [idForKnowledge]);

  useEffect(() => {
    const currentOrgOwner = allOrganizationMembersData?.find(
      (member) => member.role === OrganizationMemberRole.OWNER
    );
    setOwnerIdOfCurrentOrg(currentOrgOwner?.userId || null);
  }, [allOrganizationMembersData]);

  useEffect(() => {
    if (id) {
      // TODO: check errors
      // console.log('bumdan');
      // console.log(orgId);
      // console.log('here');
      // console.log(orgId);
      lazyGetUserProjectById({ id, orgId });
      lazyGetProjectPlugins({ id, orgId });
      lazyGetUnreadConversationsCount({ projectId: id, orgId });
    }
  }, [orgId, id]);

  // TODO: plugin names to constants ! (+ check plugin.tsx)
  // TODO: on url change to records, check if plugin is installed before redirecting
  useEffect(() => {
    setOptions(
      getProjectSettingsOptions(
        plugins,
        lazyProjectPlugins,
        lazyGetUnreadConversationsCountData || 0,
        isDomainTenantMember,
        userRole,
        user?.features
      )
    );
  }, [
    lazyProjectPlugins,
    plugins,
    lazyGetUnreadConversationsCountData,
    isDomainTenantMember,
    userRole,
  ]);

  useEffect(() => {
    getSettingsComponent();
  }, [options]);

  useEffect(() => {
    // TODO: check errors
    // console.log(projectError);
    // console.log(lazyProjectError);
    // }, [projectError, lazyProjectError]);
  }, [lazyProjectError]);

  useEffect(() => {
    lazyGetUserSubscription();
    lazyGetSubscriptionIsActive();
  }, []);

  useEffect(() => {
    const hasProcessingOrPendingKnowledge = knowledgeList.find(
      (knowledge) =>
        knowledge.status === KnowledgeStatus.PROCESSING ||
        knowledge.status === KnowledgeStatus.PENDING
    );
    if (hasProcessingOrPendingKnowledge) {
      setPollingInterval(STATUS_UPDATE_MS);
    } else {
      setPollingInterval(undefined);
    }
  }, [knowledgeList]);

  useEffect(() => {
    if (currentOrganizationLoading) return;
    if (!id) return;
    if (
      lazyUserProjectLoading ||
      (lazyUserProject && !lazyUserProject.isActive)
    )
      return;
    setIdForKnowledge(id);
  }, [id, currentOrganizationLoading, lazyUserProjectLoading]);

  useEffect(() => {
    if (!projectKnowledge || knowledgeParentId) return;
    // console.log('update')

    const knowledgeData = projectKnowledge.map((knowledge) => ({
      key: knowledge.id,
      name: knowledge.name,
      type: knowledge.type,
      isFolder: false,
      isFolderUp: false,
      // TODO: usage
      usage: '',
      status: knowledge.status,
      lastError: knowledge.lastError,
      progress: knowledge.progress,
      updatedAt: knowledge.updatedAt,
    }));
    setKnowledgeList(knowledgeData);
  }, [projectKnowledge, knowledgeParentId]);

  useEffect(() => {
    if (!userUsage) return;
    const updatedAt = currentUserSubscription?.currentPlan.updatedAt || null;

    const lsUsageInfo = localStorage.getItem(LS_USER_USAGE_INFO);
    let lsUpdatedAt = localStorage.getItem(LS_USER_USAGE_INFO_UPDATED_AT);

    if (!lsUpdatedAt && updatedAt) {
      localStorage.setItem(LS_USER_USAGE_INFO_UPDATED_AT, updatedAt);
      lsUpdatedAt = updatedAt;
    }

    const needReset = updatedAt && lsUpdatedAt && updatedAt !== lsUpdatedAt;

    if (needReset) {
      localStorage.setItem(
        LS_USER_USAGE_INFO,
        JSON.stringify({ soft: false, hard: false })
      );
      localStorage.setItem(LS_USER_USAGE_INFO_UPDATED_AT, updatedAt);
      setSoftWarning(false);
      setHardWarning(false);
    }

    let softClosed: boolean = false;
    let hardClosed: boolean = false;

    if (lsUsageInfo) {
      const lsUsageInfoClosed: IUsageInfoLimitsClosedLS =
        JSON.parse(lsUsageInfo);
      if (lsUsageInfoClosed.soft) softClosed = lsUsageInfoClosed.soft;
      if (lsUsageInfoClosed.hard) hardClosed = lsUsageInfoClosed.hard;
    } else {
      localStorage.setItem(
        LS_USER_USAGE_INFO,
        JSON.stringify({ soft: softClosed, hard: hardClosed })
      );
    }

    if (
      !softClosed &&
      !hardClosed &&
      userUsage.softLimitReached &&
      shouldShowLimitWarning()
    ) {
      setSoftWarning(true);
    }

    if (!hardClosed && userUsage.hardLimitReached && shouldShowLimitWarning()) {
      setHardWarning(true);
    }
  }, [userUsage, currentUserSubscription]);

  const shouldShowLimitWarning = () => {
    const isPersonal = currentOrganization?.isPersonal;
    const userIsOwner = ownerIdOfCurrentOrg === user?.id;
    return isPersonal || userIsOwner;
  };

  useEffect(() => {
    if (!tab) return;

    const tabsWithSubtabs: ProjectSettingsType[] = [
      ProjectSettingsType.INSIGHTS,
      ProjectSettingsType.CONVERSATIONS,
      ProjectSettingsType.SETTINGS,
      ProjectSettingsType.TWILIO_OUTBOUND,
      ProjectSettingsType.TABLES,
    ];

    if (!tabsWithSubtabs.includes(tab as ProjectSettingsType) && subtab) {
      navigate(
        `${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.PROJECT_SETTINGS_TAB_NAME}/${id}/${tab}`
      );
      return;
    }
  }, [tab, subtab]);

  const getSettingsComponent = () => {
    //TODO: if no id, redirect to projects page or show error ?
    if (!id) return <div>Ooops, no project id.</div>;

    if (
      currentOrganizationLoading ||
      lazyUserProjectLoading ||
      lazyProjectPluginsLoading
    ) {
      return <Loading height={'300px'} />;
    }

    if (
      lazyUserProject &&
      !lazyUserProject.isActive &&
      tab !== ProjectSettingsType.USAGE
    ) {
      return null;
    }

    const optionsHasRecords = options.find(
      (option) => option.value === ProjectSettingsType.RECORDS
    );

    if (isDomainTenantMember) {
      switch (tab) {
        case ProjectSettingsType.CONVERSATIONS:
          return <Conversations projectId={id} />;
        case ProjectSettingsType.RECORDS:
          return optionsHasRecords ? (
            <Records projectId={id} />
          ) : (
            <Navigate
              to={`${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.PROJECT_SETTINGS_TAB_NAME}/${id}/${ProjectSettingsType.KNOWLEDGE}`}
            />
          );
        case ProjectSettingsType.ANALYTICS:
          return <Analytics projectId={id} />;
        default:
          return (
            <Navigate
              to={`${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.PROJECT_SETTINGS_TAB_NAME}/${id}/${ProjectSettingsType.CONVERSATIONS}`}
            />
          );
      }
    }

    switch (tab) {
      case ProjectSettingsType.KNOWLEDGE:
        return (
          <Knowledge
            projectId={id}
            knowledgeList={knowledgeList}
            setKnowledgeList={setKnowledgeList}
            error={error}
          />
        );
      case ProjectSettingsType.INSIGHTS:
        return <Insights projectId={id} />;
      case ProjectSettingsType.CONVERSATIONS:
        return <Conversations projectId={id} />;
      case ProjectSettingsType.PLUGINS:
        return <Plugins projectId={id} />;
      case ProjectSettingsType.PROMPT:
        return (
          <Prompt projectId={id} ownerIdOfCurrentOrg={ownerIdOfCurrentOrg} />
        );
      case ProjectSettingsType.SETTINGS:
        return (
          <Settings projectId={id} ownerIdOfCurrentOrg={ownerIdOfCurrentOrg} />
        );
      case ProjectSettingsType.RECORDS:
        return optionsHasRecords ? (
          <Records projectId={id} />
        ) : (
          <Navigate
            to={`${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.PROJECT_SETTINGS_TAB_NAME}/${id}/${ProjectSettingsType.KNOWLEDGE}`}
          />
        );
      case ProjectSettingsType.USAGE:
        return <Usage projectId={id} />;
      case ProjectSettingsType.TWILIO_OUTBOUND:
        return (
          <TwilioOutbound projectId={id} projectPlugins={lazyProjectPlugins} />
        );
      case ProjectSettingsType.ANALYTICS:
        return <Analytics projectId={id} />;
      case ProjectSettingsType.TABLES:
        if (userRole === UserRoles.ADMIN) {
          return <Tables projectId={id} />;
        } else {
          return (
            <Navigate
              to={`${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.PROJECT_SETTINGS_TAB_NAME}/${id}/${ProjectSettingsType.KNOWLEDGE}`}
            />
          );
        }
      default:
        return (
          <Navigate
            to={`${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.PROJECT_SETTINGS_TAB_NAME}/${id}/${ProjectSettingsType.KNOWLEDGE}`}
          />
        );
    }
  };

  const navigateToPersonalProjects = () => {
    const personalOrg = allOrganizationsInfo.find((org) => org.isPersonal);

    if (personalOrg) {
      navigate(`${RouteNames.USER_PROJECTS}/${personalOrg.id}`);
    } else {
      navigate(`${RouteNames.USER_PROJECTS}`);
    }
  };

  const projectSettingsPage = () => (
    <div className="project-settings project-settings--v2">
      <div className="container">
        <div className="project-settings__inner">
          {/*TODO: make normal breadcrumbs (in all project)*/}
          <BreadcrumbNav />
          <div className={'project-settings__inner-container'}>
            <ProjectSettingsSideMenu options={options} />
            {lazyProjectError ? (
              <Result
                style={{ width: '100%' }}
                status="error"
                title={
                  (lazyProjectError && getErrorMessage(lazyProjectError)) ||
                  'Something went wrong.'
                }
                extra={
                  <Button
                    type="primary"
                    key="console"
                    onClick={navigateToPersonalProjects}
                  >
                    Go To My Projects
                  </Button>
                }
              />
            ) : (
              <>
                <div className="project-setting-container project-setting-container--side-menu">
                  <div className="project-settings__type">
                    <ProjectSettingsMenu options={options} />
                    <Link
                      to={`${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.DEMO_TAB_NAME}/${id}`}
                      className={'project-settings__try-button-extra btn-link'}
                    >
                      {t(i18nKeys.TRY_IT_OUT)}
                    </Link>
                  </div>
                  {subscriptionIsActive ? null : (
                    <SubscriptionInactiveWarning />
                  )}
                  {hardWarning ? (
                    <LimitWarning type={'error'} />
                  ) : softWarning ? (
                    <LimitWarning type={'warning'} />
                  ) : null}
                  <Divider
                    style={{ marginTop: -27 }}
                    className={'project-settings-menu-divider'}
                  />
                  {lazyUserProject && !lazyUserProject.isActive && (
                    <Alert
                      message="Project is inactive."
                      type="warning"
                      showIcon
                      className="warning--content-align-center"
                    />
                  )}
                  {getSettingsComponent()}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <CheckAuth
      component={projectSettingsPage()}
      allowedForDomainTenantMember={true}
    />
  );
};

export default ProjectSettings;
