import { IMenuOption } from './project-settings-menu';
import {
  PluginNames,
  ProjectSettingsType,
  UserRoles,
} from '../../shared/constants';
import IPlugin from '../../types/IPlugin';
import IProjectPlugin from '../../types/IProjectPlugin';
import {
  AdminOptions,
  DomainTenantMemberOptions,
  IProjectSettingsOptions,
  ProjectSettingDefaultOptions,
  RecordsOption,
  UserFeaturesOptions,
} from './project-settings-options';
import { IUserFeatures } from '../../types/IUser';

const transformOptions = (
  options: IMenuOption[],
  convsUnreadCount: number
): IMenuOption[] => {
  return options.map((option) => {
    if (option.value === ProjectSettingsType.CONVERSATIONS) {
      return {
        ...option,
        label: `Conversations ${
          convsUnreadCount ? `(${convsUnreadCount})` : ''
        }`,
      };
    }
    return option;
  });
};

export const findPlugin = (name: string, plugins: IPlugin[]) => {
  return plugins.find((plugin) => plugin.name === name);
};

export const findInstalledProjectPlugin = (
  plugin: IPlugin | undefined,
  projectPlugins: IProjectPlugin[]
) => {
  return projectPlugins.find(
    (projectPlugin) => projectPlugin.pluginId === plugin?.id
  );
};

export const getProjectSettingsOptions = (
  plugins: IPlugin[],
  projectPlugins: IProjectPlugin[] | undefined,
  convsUnreadCount: number,
  isDomainTenantMember: boolean,
  userRole: UserRoles | null,
  userFeatures: IUserFeatures | undefined
): IProjectSettingsOptions[] => {
  if (isDomainTenantMember) {
    const defOptions = transformOptions(
      DomainTenantMemberOptions,
      convsUnreadCount
    );
    if (projectPlugins && plugins.length > 0) {
      const recordsPlugin = findPlugin(PluginNames.RECORDS, plugins);
      const adfEmailPlugin = findPlugin(PluginNames.ADF_PLUGIN, plugins);

      if (!recordsPlugin && !adfEmailPlugin) return defOptions;
      const recordsOrAdfEmailPluginInstalled =
        findInstalledProjectPlugin(recordsPlugin, projectPlugins) ||
        findInstalledProjectPlugin(adfEmailPlugin, projectPlugins);
      if (!recordsOrAdfEmailPluginInstalled) {
        return defOptions;
      }

      const options = [
        ...defOptions.slice(0, 1),
        RecordsOption,
        ...defOptions.slice(1),
      ];

      return options;
    }

    return defOptions;
  }

  const defOptions = transformOptions(
    ProjectSettingDefaultOptions,
    convsUnreadCount
  );

  if (userRole === UserRoles.ADMIN) {
    defOptions.push(...AdminOptions);
  }

  if (userFeatures) {
    for (const key in userFeatures) {
      if (Object.prototype.hasOwnProperty.call(userFeatures, key)) {
        const featureEnabled = userFeatures[key as keyof IUserFeatures];

        if (featureEnabled) {
          var feature = UserFeaturesOptions.filter((f) => {
            return f.label.toLowerCase() == key;
          });

          if (feature.length > 0) {
            defOptions.push(feature[0]);
          }
        }
      }
    }
  }

  if (projectPlugins && plugins.length > 0) {
    const recordsPlugin = findPlugin(PluginNames.RECORDS, plugins);
    const adfEmailPlugin = findPlugin(PluginNames.ADF_PLUGIN, plugins);

    if (!recordsPlugin && !adfEmailPlugin) return defOptions;
    const recordsOrAdfEmailPluginInstalled =
      findInstalledProjectPlugin(recordsPlugin, projectPlugins) ||
      findInstalledProjectPlugin(adfEmailPlugin, projectPlugins);
    if (!recordsOrAdfEmailPluginInstalled) {
      return defOptions;
    }

    const options = [
      ...defOptions.slice(0, 3),
      RecordsOption,
      ...defOptions.slice(3),
    ];

    return options;
  }

  return defOptions;
};
