import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './widget-pro-showcase-website.scss';
import { ON_SDK_LOADED, SDK_NAME } from '../../shared/constants';
import { connectWidgetV2Script } from '../../shared/helpers';

interface IWidgetProShowcaseWebsiteParams {
  url: string;
}

const WidgetProShowcaseWebsite: FC<IWidgetProShowcaseWebsiteParams> = ({
  url,
}) => {
  const { id } = useParams();
  const [widgetV2ScriptLoading, setWidgetV2ScriptLoading] =
    useState<boolean>(false);

  useEffect(() => {
    if (!id) {
      return;
    }

    //@ts-ignore
    window.widgetConfigs = [
      {
        id: id,
        miniChat: {
          enabled: true,
        },
        allowFullScreenMode: true,
        closeButton: true,
        followPages: false,
      },
    ];

    //@ts-ignore
    window[ON_SDK_LOADED] = () => {
      //@ts-ignore
      window[SDK_NAME].setPosition('bottom-right');
    };

    const removeExistingWidgetScript = () => {
      const script = document.getElementById('widgetScript');
      if (script) {
        document.body.removeChild(script);
      }
    };

    removeExistingWidgetScript();
    connectWidgetV2Script(setWidgetV2ScriptLoading);

    return () => {
      removeExistingWidgetScript();
    };
  }, [id]);

  return (
    <div className={'widget-pro-showcase-website'}>
      <iframe
        src={url}
        width="100%"
        height="100%"
        className={'widget-pro-showcase-website__iframe'}
      />
    </div>
  );
};

export default WidgetProShowcaseWebsite;
