import IConversation from '../types/IConversation';
import dayjs, { Dayjs } from 'dayjs';
import { TimeRangePickerProps } from 'antd';
import ITableRecordsData from '../types/ITableRecordsData';

export const getRangePresets = () =>
  //  data: IConversation[] | ITableRecordsData[] | undefined
  {
    // const getOldestConversationDate = () => {
    //   if (!data || data.length === 0) return dayjs('2022-02-02');
    //
    //   const lastItem = data[data.length - 1];
    //
    //   // @ts-ignore
    //   return dayjs(lastItem.updatedAt || lastItem.createAt || lastItem.createdAt);
    // };

    const rangePresets: TimeRangePickerProps['presets'] = [
      { label: 'Today', value: [dayjs(), dayjs()] },
      {
        label: 'Yesterday',
        value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')],
      },
      { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
      { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
      { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
      { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
      { label: 'All Time', value: [dayjs('2022-02-02'), dayjs()] },
    ];

    return rangePresets;
  };

export const datesRangeToUTC = (from: string, to: string) => {
  const startDate = new Date(from);
  const endDate = new Date(to);

  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);

  endDate.setDate(endDate.getDate() + 1);

  const startISOString = startDate.toISOString();
  const endISOString = endDate.toISOString();

  return {
    start: startISOString.slice(0, 10),
    end: endISOString.slice(0, 10),
  };
};

export const getDatesRange = (from: string, to: string) => {
  const startDate = new Date(from);
  const endDate = new Date(to);

  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);

  endDate.setDate(endDate.getDate() + 1);

  const start = `${startDate.getFullYear()}-${(startDate.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
  const end = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`;

  return {
    start,
    end,
  };
};
