import {
  BarChartOutlined,
  BulbOutlined,
  CommentOutlined,
  FormOutlined,
  PieChartOutlined,
  ReadOutlined,
  SettingOutlined,
  SlidersOutlined,
  DatabaseOutlined,
  TableOutlined,
} from '@ant-design/icons';
import { ProjectSettingsType } from '../../shared/constants';
import { SiTwilio } from 'react-icons/si';
import { IUserFeatures } from '../../types/IUser';

export interface IProjectSettingsOptions {
  label: string;
  value: ProjectSettingsType;
  key: ProjectSettingsType;
  icon: any;
}

export const ProjectSettingDefaultOptions: IProjectSettingsOptions[] = [
  {
    label: 'Knowledge',
    value: ProjectSettingsType.KNOWLEDGE,
    key: ProjectSettingsType.KNOWLEDGE,
    icon: ReadOutlined,
  },
  {
    label: 'Insights',
    value: ProjectSettingsType.INSIGHTS,
    key: ProjectSettingsType.INSIGHTS,
    icon: BulbOutlined,
  },
  {
    label: 'Conversations',
    value: ProjectSettingsType.CONVERSATIONS,
    key: ProjectSettingsType.CONVERSATIONS,
    icon: CommentOutlined,
  },
  {
    label: 'Plugins',
    value: ProjectSettingsType.PLUGINS,
    key: ProjectSettingsType.PLUGINS,
    icon: SlidersOutlined,
  },
  {
    label: 'Prompt',
    value: ProjectSettingsType.PROMPT,
    key: ProjectSettingsType.PROMPT,
    icon: FormOutlined,
  },
  {
    label: 'Settings',
    value: ProjectSettingsType.SETTINGS,
    key: ProjectSettingsType.SETTINGS,
    icon: SettingOutlined,
  },
  {
    label: 'Usage',
    value: ProjectSettingsType.USAGE,
    key: ProjectSettingsType.USAGE,
    icon: PieChartOutlined,
  },
  {
    label: 'Analytics',
    value: ProjectSettingsType.ANALYTICS,
    key: ProjectSettingsType.ANALYTICS,
    icon: BarChartOutlined,
  },
  {
    label: 'Twilio Outbound',
    value: ProjectSettingsType.TWILIO_OUTBOUND,
    key: ProjectSettingsType.TWILIO_OUTBOUND,
    icon: SiTwilio,
  },
];

export const AdminOptions = [];

const FeatureLabels: { [K in keyof IUserFeatures]: string } = {
  tables: 'Tables',
  routes: 'Routes',
};

export const UserFeaturesOptions = [
  {
    label: FeatureLabels.tables,
    value: ProjectSettingsType.TABLES,
    key: ProjectSettingsType.TABLES,
    icon: TableOutlined,
  },
];

export const RecordsOption: IProjectSettingsOptions = {
  label: 'Records',
  value: ProjectSettingsType.RECORDS,
  key: ProjectSettingsType.RECORDS,
  icon: DatabaseOutlined,
};

export const DomainTenantMemberOptions: IProjectSettingsOptions[] = [
  {
    label: 'Conversations',
    value: ProjectSettingsType.CONVERSATIONS,
    key: ProjectSettingsType.CONVERSATIONS,
    icon: CommentOutlined,
  },
  {
    label: 'Analytics',
    value: ProjectSettingsType.ANALYTICS,
    key: ProjectSettingsType.ANALYTICS,
    icon: BarChartOutlined,
  },
];
